import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type ReportRowProps = {
  label: ReactNode
  children: ReactNode
  dataTest?: string
}

export const ReportRow = ({ label, children, dataTest }: ReportRowProps) => (
  <div>
    <Typography.Text strong style={{ color: '#688699', display: 'block' }}>
      {label}
    </Typography.Text>
    <Typography.Text
      strong
      style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}
      {...(dataTest && { 'data-test': dataTest })}
    >
      {children}
    </Typography.Text>
  </div>
)
