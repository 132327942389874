import { UntitledIcon } from '@faceup/icons'
import { useMatchPath, useNavigate } from '@faceup/router'
import { pagesIcons, usePageTemplate } from '@faceup/ui'
import { Button } from '@faceup/ui-base'
import type { PageIcon } from '@faceup/utils'
import { Stack } from '@mantine/core'
import { useEffect } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { getPageRouteCallback } from '../../../../../utils/urls'

const fragments = {
  InstitutionLayoutNavbarContent_reportSource: graphql(`
    fragment InstitutionLayoutNavbarContent_reportSource on PublicReportSource {
      id
      isDefault

      pages(language: $language) {
        ... on Page {
          id
          icon
          title
        }
      }
    }
  `),
}

type NavbarContentProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutNavbarContent_reportSource>
}

export const NavbarContent = ({ reportSource: _reportSource }: NavbarContentProps) => {
  const reportSource = getFragmentData(
    fragments.InstitutionLayoutNavbarContent_reportSource,
    _reportSource
  )
  const matchPath = useMatchPath()
  const navigate = useNavigate()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const pages = reportSource.pages.map(page =>
    page.__typename === 'Page' ? fillObject(page) : null
  )

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  return (
    <Stack>
      {pages.map(page => {
        if (page?.__typename !== 'Page') {
          return null
        }

        const { id, title, icon } = page
        const untitledIcon = pagesIcons[icon as PageIcon]
        const routeCallback = getPageRouteCallback(page)
        const isItemActive = matchPath(routeCallback)

        return (
          <Button
            key={id}
            onClick={() => navigate(routeCallback)}
            icon={untitledIcon ? <UntitledIcon icon={untitledIcon} size={19} /> : undefined}
            block
            type={isItemActive ? 'link' : 'text'}
            className='!justify-start'
          >
            {title}
          </Button>
        )
      })}
    </Stack>
  )
}
