import {
  Navigate,
  Outlet,
  type RouteObject,
  RouterProvider,
  RoutesProvider,
  ScrollRestoration,
  createBrowserRouter,
} from '@faceup/router'
import { Box, LoadingOverlay } from '@mantine/core'
import { useContext, useEffect } from 'react'
import EmailVerification from '../Components/EmailVerification'
import { FullScreenLoader } from '../Components/FullScreenLoader'
import { InstitutionLayout } from '../Components/InstitutionLayout'
import NavigationChange from '../Components/NavigationChange'
import PointerEventsMember from '../Components/PointerEventsMember'
import { LanguageContext } from '../Contexts/LanguageContext'
import { LayoutContext } from '../Contexts/LayoutContext'
import { UserContext } from '../Contexts/UserContext'
import { useRoutes } from '../hooks/useRoutes'
import { useSavedReportsContext } from '../hooks/useSavedReportsContext'
import useCustomizationByURLParams from '../utils/useCustomizationByURLParams'
import Biometrics from './Authentication/BiometricsPage'
import LandingPage from './Authentication/LandingPage'
import CheckReport from './CheckReport'
import { CheckReportList } from './CheckReportList'
import CreateReport from './CreateReport/CreateReport'
import Offline from './Offline/Offline'
import DefaultPageRedirect from './Page/DefaultPageRedirect'
import PageView from './Page/PageView'
import Report from './Report/Report'
import ReportSent from './ReportSent'
import SearchInstitution from './SearchInstitution/SearchInstitution'
import ShortIdRedirect from './ShortIdRedirect'
import SurveyView from './Survey/SurveyView'

const AppRouter = () => {
  const { basename, messages } = useContext(LanguageContext)
  const { reportSource, isAuthenticated, isSetUp } = useContext(UserContext)
  const { setClient, client } = useContext(LayoutContext)
  const isApp = client === 'app'
  const { hasSomeSavedReports } = useSavedReportsContext()
  const { customizeClient } = useCustomizationByURLParams(window.location.search)
  const routeLinks = useRoutes()

  // biome-ignore lint/correctness/useExhaustiveDependencies(customizeClient):
  // biome-ignore lint/correctness/useExhaustiveDependencies(setClient):
  useEffect(() => {
    const client = customizeClient()
    if (client) {
      setClient(client)
    }
  }, [])

  const routes: RouteObject[] = [
    {
      path: '',
      element: !isSetUp() ? (
        <Navigate to={routes => routes.settingsBiometrics()} />
      ) : reportSource?.institutionId ? (
        <Navigate to={routes => routes.page()} />
      ) : (
        <SearchInstitution />
      ),
    },
    { path: 's/:shortId', Component: ShortIdRedirect },
    { path: 'c/:shortId', Component: ShortIdRedirect },
    { path: 's/:shortId/check', Component: ShortIdRedirect },
    { path: 'c/:shortId/check', Component: ShortIdRedirect },
    { path: 'q/:shortId', Component: ShortIdRedirect },
    {
      path: 'create',
      element: (
        <InstitutionLayout variant='simple'>
          <CreateReport />
        </InstitutionLayout>
      ),
    },
    {
      path: 'sent',
      element: (
        <InstitutionLayout variant='simple' headerVariant='simple-no-back-button'>
          <ReportSent />
        </InstitutionLayout>
      ),
    },
    {
      path: 'check',
      element: (
        <InstitutionLayout variant='simple'>
          {isApp && hasSomeSavedReports ? <CheckReportList /> : <CheckReport />}
        </InstitutionLayout>
      ),
    },
    ...(isApp
      ? [
          {
            path: 'check/form',
            element: (
              <InstitutionLayout variant='simple'>
                <CheckReport />
              </InstitutionLayout>
            ),
          },
        ]
      : []),
    {
      path: 'report',
      element: (
        <InstitutionLayout variant='simple'>
          <Report />
        </InstitutionLayout>
      ),
    },
    {
      path: 'page/:id',
      element: (
        <InstitutionLayout>
          <PageView />
        </InstitutionLayout>
      ),
    },
    { path: 'page', Component: DefaultPageRedirect },
    { path: 'survey/:id', Component: SurveyView },
    { path: 'token/:id', Component: EmailVerification },
    ...(isApp ? [{ path: 'settings/biometrics', Component: Biometrics }] : []),
    { path: 'offline', Component: Offline },
    {
      path: '*',
      element: (
        <Navigate
          to={routes => routes.home()} // replace
        />
      ),
    },
  ]

  const router = createBrowserRouter([
    {
      path: `/${basename}`,
      element: (
        <RoutesProvider routes={routeLinks}>
          <NavigationChange />
          <PointerEventsMember>
            <ScrollRestoration />
            <Outlet />
          </PointerEventsMember>
        </RoutesProvider>
      ),
      children: routes,
    },
    { path: '/*', Component: FullScreenLoader },
  ])

  return (
    <>
      <LoadingOverlay visible={!messages} />
      {/* We want to keep Router rendered, therefore we only hide it with CSS
          When app enters backround and user is logged out, we want to keep
          the state of app including input states, thats why it is only hidden
       */}
      {!isAuthenticated && <LandingPage />}
      <Box
        sx={
          isAuthenticated
            ? {}
            : {
                // can't be simply display: none, because it sets footer height in app to 0
                // and menu is not visible
                visibility: 'hidden',
                position: 'absolute',
                top: 0,
                maxHeight: '100vh',
                overflow: 'hidden',
              }
        }
      >
        <RouterProvider router={router} />
      </Box>
    </>
  )
}

export default AppRouter
