import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { Link, useMatchPath } from '@faceup/router'
import { pagesIcons, usePageTemplate } from '@faceup/ui'
import { Button } from '@faceup/ui-base'
import type { PageIcon } from '@faceup/utils'
import { Flex, Menu } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, defineMessages } from '../../../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../../__generated__'
import { getPageRouteCallback } from '../../../../../../../utils/urls'
import { MenuItem } from './components'
import { countVisibleElements } from './utils'

const messages = defineMessages({
  moreMenuItems: 'FollowUp.layout.menu.moreMenuItems',
})

const fragments = {
  PagesMenu_reportSource: graphql(`
    fragment PagesMenu_reportSource on PublicReportSource {
      id
      isDefault
      pages(language: $language) {
        ... on Page {
          id
          icon
          title
        }
      }
    }
  `),
}

type PagesMenuProps = {
  reportSource: FragmentType<typeof fragments.PagesMenu_reportSource>
}

export const PagesMenu = ({ reportSource: _reportSource }: PagesMenuProps) => {
  const reportSource = getFragmentData(fragments.PagesMenu_reportSource, _reportSource)
  const [shownItems, setShownItems] = useState<number>()
  const [itemsWidth, setItemsWidth] = useState<number[]>()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const matchPath = useMatchPath()
  const { ref, width: wrapperWidth } = useElementSize()
  const buttonMoreItemsRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (ref.current) {
      const children = [...ref.current.childNodes]
      setItemsWidth(children.map(child => child.offsetWidth))
    }
  }, [ref])

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  useEffect(() => {
    if (itemsWidth && wrapperWidth && buttonMoreItemsRef.current) {
      let shownItems = countVisibleElements(itemsWidth, wrapperWidth)
      // We need space for the "More items" button
      if (shownItems < itemsWidth.length) {
        shownItems = countVisibleElements(
          itemsWidth,
          wrapperWidth - buttonMoreItemsRef.current.offsetWidth
        )
      }
      setShownItems(shownItems)
    }
  }, [itemsWidth, wrapperWidth])

  const pagesShown = useMemo(
    () => reportSource.pages.slice(0, shownItems),
    [reportSource, shownItems]
  )
  const pagesDropdown = useMemo(
    () => reportSource.pages.slice(shownItems),
    [reportSource, shownItems]
  )

  return (
    <Flex
      ref={ref}
      wrap='nowrap'
      sx={{
        overflow: 'hidden',
        flex: 1,
      }}
      gap='xl'
    >
      {pagesShown.map((page, index) => {
        if (page.__typename === 'Page') {
          return (
            <MenuItem
              key={page.id}
              page={page}
              onChangeElementWidth={(width: number) => {
                if (!itemsWidth || itemsWidth[index] !== width) {
                  setItemsWidth(prevState => {
                    const newState = prevState === undefined ? [] : [...prevState]
                    newState[index] = width
                    return newState
                  })
                }
              }}
            />
          )
        }
        return null
      })}
      <Menu position='top-end'>
        <Menu.Target ref={buttonMoreItemsRef}>
          <Button
            type='text'
            icon={<UntitledIcon icon={ulChevronDown} size={18} />}
            className={pagesDropdown.length === 0 ? '!hidden' : ''}
          >
            <FormattedMessage {...messages.moreMenuItems} />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {pagesDropdown.map(page => {
            if (page.__typename === 'Page') {
              const { id, icon, title } = fillObject(page)
              const untitledIcon = pagesIcons[icon as PageIcon]
              const routeCallback = getPageRouteCallback(page)
              const isItemActive = matchPath(routeCallback)

              return (
                <Menu.Item
                  key={id}
                  component={Link}
                  to={routeCallback}
                  icon={untitledIcon ? <UntitledIcon icon={untitledIcon} /> : undefined}
                  color={isItemActive ? 'primary' : undefined}
                >
                  {title}
                </Menu.Item>
              )
            }
            return null
          })}
        </Menu.Dropdown>
      </Menu>
    </Flex>
  )
}
