import { downloadFileWithReportKey, getUnmodifiedKeys, readReport } from '@faceup/crypto'
import { usAttachment02 } from '@faceup/icons/usAttachment02'
import { useLocalization } from '@faceup/localization'
import { AttachmentList, FormItemsAnswers, ReportStatus } from '@faceup/report'
import { Link, useNavigate } from '@faceup/router'
import { ArrowGoBack, Card, TextUrlfy } from '@faceup/ui'
import { Button, Col, Icon, Row, Space, Typography, notification } from '@faceup/ui-base'
import {
  CaseStatus,
  FormItemType,
  Institution,
  type Language,
  getTranslation as utilsGetTranslation,
} from '@faceup/utils'
import moment from 'moment-timezone'
import { useContext, useEffect, useMemo, useState } from 'react'
import EmailNotificationsCheckbox from '../../Components/EmailNotificationsCheckbox'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useSavedReportsContext } from '../../hooks/useSavedReportsContext'
import { HideMedia } from '../../mq'
import useAuth from '../../utils/auth'
import useMobileFileDownload from '../../utils/useMobileFileDownload'
import useReportAuth from '../../utils/useReportAuth'
import CheckReportIcon from './assets/check-report-icon.svg?react'
import { ReportRow } from './components'

const { Title } = Typography

const fragments = {
  CompanyReportInfo_report: graphql(`
    fragment CompanyReportInfo_report on CompanyReport {
      id
      createdAt
      lastChangeDate
      status
      isDeleted
      tag
      company {
        id
        type
      }

      encryptionKey

      translation {
        id
        body
        bodyNonce
      }

      attachments {
        id
        name
        url
        mimetype
      }

      category {
        id
        name
      }

      answers {
        id
        formItem {
          id
          hasHint
          type
          hintTranslations {
            language
            translation
          }
          labelTranslations {
            language
            translation
          }
        }
      }

      reportSource {
        id
        isDefault
        defaultLanguage
        config {
          ... on FormReportSourceConfiguration {
            id
            emailNotificationEnabled
            identificationFormItems {
              id
              type
              labelTranslations {
                language
                translation
              }
            }
          }
        }
      }

      ...FormItemsAnswers_companyReport
    }
  `),
  CompanyReportInfo_viewer: graphql(`
    fragment CompanyReportInfo_viewer on CompanyVictim {
      email
      isEmailVerified
      ...EmailNotificationsCheckbox_viewer
    }
  `),
}

const messages = defineMessages({
  report: 'FollowUp.report.detail.title',
  organization: 'Shared.report.organization',
  school: 'Shared.report.school',
  created: 'Shared.report.created',
  lastUpdated: 'FollowUp.report.lastUpdated',
  classRoom: 'Shared.report.classRoom',
  victimName: 'Shared.report.victimName',
  category: 'Shared.report.category',
  moreInfo: 'Shared.report.moreInfo',
  attachments: 'Shared.report.attachments',
  anonymous: 'Shared.chat.anonymous',
  checkAnotherReport: 'FollowUp.report.checkAnotherReport',
  notification: 'FollowUp.report.notification',
  categoryLabel: 'Shared.report.category',
  institutionLabel: 'Shared.report.organization',
  moreInfoLabel: 'Shared.report.moreInfo',
  moreInfoDetailSchool: 'Shared.CreateReportForm.school.moreInfoDetail',
  moreInfoDetailCompany: 'Shared.CreateReportForm.company.moreInfoDetail',
})

type Props = {
  loading: boolean
  report: FragmentType<typeof fragments.CompanyReportInfo_report> | null
  viewer: FragmentType<typeof fragments.CompanyReportInfo_viewer> | null
}

const ReportInfo = ({ loading, report: _report, viewer: _viewer }: Props) => {
  const report = getFragmentData(fragments.CompanyReportInfo_report, _report)
  const viewer = getFragmentData(fragments.CompanyReportInfo_viewer, _viewer)
  const { formatMessage } = useIntl()
  const { client } = useContext(LayoutContext)
  const { setIsAnonymous } = useContext(ReportFormContext)
  const { language } = useLocalization()
  const { logout } = useReportAuth()
  const fileDownloadHandler = useMobileFileDownload()
  const navigate = useNavigate()
  const [moreInfo, setMoreInfo] = useState('')
  const [victimName, setVictimName] = useState('')
  const { getJwt } = useAuth()
  const { addReport } = useSavedReportsContext()
  const isApp = client === 'app'

  const jwt = useMemo(() => getJwt(), [getJwt])

  // biome-ignore lint/correctness/useExhaustiveDependencies(addReport):
  useEffect(() => {
    const decryptReport = async () => {
      if (!report) {
        return
      }

      const body = report?.translation?.body
      const bodyNonce = report?.translation?.bodyNonce

      const payload = await readReport(body ?? '', bodyNonce ?? '', report?.encryptionKey ?? '')

      if (payload.isErr()) {
        console.error(payload)
        notification.error({
          message: formatMessage(sharedMessages.encryptionError),
          description: payload.error.message,
        })
        return
      }

      const { moreInfo: decryptedMoreInfo, victimName: decryptedVictimName } = payload.value

      setMoreInfo(decryptedMoreInfo ?? '')
      setVictimName(decryptedVictimName ?? '')
      setIsAnonymous(!decryptedVictimName)

      const { privateKey, publicKey } = getUnmodifiedKeys()
      if (report && jwt && isApp && privateKey && publicKey) {
        addReport({
          keys: {
            jwt,
            publicKey,
            privateKey,
          },
          id: report.tag ?? '',
          createdAt: report.createdAt ?? '',
          category: report.category.name,
          content: decryptedMoreInfo ?? '',
          status: report.status ?? CaseStatus.Open,
        })
      }
    }

    decryptReport()
    // `addReport` creates infinite loop
  }, [formatMessage, report, setIsAnonymous, jwt, isApp])

  const isSchoolDefaultForm =
    report?.company?.type === Institution.School && report.reportSource.isDefault

  const getTranslation = <D extends Language>(
    translations: { language: D; translation: string }[] | undefined
  ): string =>
    utilsGetTranslation(translations ?? [], language, report?.reportSource.defaultLanguage)

  const formItemCategories = report?.answers?.find(
    answer => answer.formItem.type === FormItemType.Category
  )?.formItem
  const formItemMoreInformation = report?.answers?.find(
    answer => answer.formItem.type === FormItemType.MoreInformation
  )?.formItem

  const formItemClassroom = report?.answers?.find(
    answer => answer.formItem.type === FormItemType.Classroom
  )?.formItem

  const config =
    report?.reportSource?.config?.__typename === 'FormReportSourceConfiguration'
      ? report?.reportSource?.config
      : null
  const formItemSenderName = config?.identificationFormItems.find(
    formItem => formItem.type === FormItemType.SenderName
  )
  const senderNameLabel = getTranslation(formItemSenderName?.labelTranslations) || (
    <FormattedMessage {...sharedMessages.globalNameLabel} />
  )
  const categoriesLabel = getTranslation(formItemCategories?.labelTranslations) || (
    <FormattedMessage {...messages.categoryLabel} />
  )
  const moreInformationLabel = getTranslation(formItemMoreInformation?.labelTranslations) || (
    <FormattedMessage {...messages.moreInfoLabel} />
  )
  const classRoomLabel = getTranslation(formItemClassroom?.labelTranslations) || (
    <FormattedMessage {...messages.classRoom} />
  )

  return (
    <Card loading={loading} className='p-6'>
      <Row gutter={24} style={{ marginBottom: 16 }} justify='space-between'>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Space size={16} wrap>
            <Title level={2} style={{ display: 'inline-block' }}>
              <Space wrap>
                {isApp && (
                  <Link to={routes => routes.checkReport()}>
                    <ArrowGoBack />
                  </Link>
                )}
                {formatMessage(messages.report)}
                {`#${report?.tag}`}
              </Space>
            </Title>
            <div data-test='report-info-status'>
              <ReportStatus status={report?.status ?? CaseStatus.Open} />
            </div>
          </Space>
        </Col>
        <HideMedia hideWhen='lgDown'>
          <Col>
            <Button
              onClick={() => {
                logout()
                navigate(routes => routes.checkReport(), { replace: true })
              }}
              type='text'
            >
              <Space size={12}>
                <Icon style={{ fontSize: 19, display: 'flex' }} component={CheckReportIcon} />
                <FormattedMessage {...messages.checkAnotherReport} />
              </Space>
            </Button>
          </Col>
        </HideMedia>
      </Row>
      <Row
        gutter={24}
        style={{ borderRadius: 6, background: '#f0f2f4', padding: '16px 2px', marginBottom: 16 }}
      >
        <Col>
          <ReportRow label={<FormattedMessage {...messages.created} />}>
            {moment(report?.createdAt).format('LLL')}
          </ReportRow>
        </Col>
        <Col>
          <ReportRow label={<FormattedMessage {...messages.lastUpdated} />}>
            {moment(report?.lastChangeDate).format('LLL')}
          </ReportRow>
        </Col>
      </Row>
      {isSchoolDefaultForm && (
        <Row gutter={24} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <ReportRow label={<FormattedMessage {...messages.victimName} />}>
              {victimName || <FormattedMessage {...messages.anonymous} />}
            </ReportRow>
          </Col>
        </Row>
      )}
      {!isSchoolDefaultForm && (
        <Row gutter={24} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <ReportRow label={senderNameLabel} dataTest='report-info-victim'>
              {victimName || <FormattedMessage {...messages.anonymous} />}
            </ReportRow>
          </Col>
        </Row>
      )}
      <Row gutter={24} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <ReportRow label={categoriesLabel} dataTest='report-info-category'>
            {report?.category?.name}
          </ReportRow>
        </Col>
      </Row>

      {report && (
        <FormItemsAnswers
          companyReport={report}
          renderFormItemAnswer={({ label, value, type }) => {
            if ([FormItemType.Category, FormItemType.OrganizationalUnit].includes(type)) {
              return null
            }
            if (type === FormItemType.MoreInformation && moreInfo) {
              return (
                <Row gutter={24} style={{ marginBottom: 16 }}>
                  <Col span={24}>
                    <ReportRow label={moreInformationLabel} dataTest='report-info-more-info'>
                      <TextUrlfy text={moreInfo} />
                    </ReportRow>
                  </Col>
                </Row>
              )
            }
            return (
              <Row gutter={24} style={{ marginBottom: 16 }}>
                <Col span={24}>
                  <ReportRow label={type === FormItemType.Classroom ? classRoomLabel : label}>
                    {value}
                  </ReportRow>
                </Col>
              </Row>
            )
          }}
        />
      )}

      {Boolean(report?.attachments?.length) && (
        <Row gutter={24} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <ReportRow label={<FormattedMessage {...messages.attachments} />}>
              <AttachmentList
                style={{ marginTop: '0.5rem' }}
                attachments={report?.attachments ?? []}
                translations={{
                  download: formatMessage(sharedMessages.download),
                }}
                icons={{
                  attachment: usAttachment02,
                }}
                download={async attachment =>
                  !(await downloadFileWithReportKey(
                    attachment,
                    report?.encryptionKey ?? '',
                    client === 'app' ? fileDownloadHandler : undefined
                  ))
                }
              />
            </ReportRow>
          </Col>
        </Row>
      )}
      {((report?.reportSource?.config?.__typename === 'FormReportSourceConfiguration' &&
        report.reportSource.config.emailNotificationEnabled) ||
        viewer?.email) && (
        <Row gutter={24}>
          <Col span={24}>
            <EmailNotificationsCheckbox viewer={viewer} />
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default ReportInfo
