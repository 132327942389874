import { UntitledIcon } from '@faceup/icons'
import { usAlertTriangle } from '@faceup/icons/usAlertTriangle'
import { Form, MoreInfo } from '@faceup/ui'
import { Button, Popconfirm, Tooltip } from '@faceup/ui-base'
import { isCompanyEmail as isCompanyEmailFn, isEmail } from '@faceup/utils'
import { Box, Checkbox, Flex, Stack, TextInput, useMantineTheme } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { sharedMessages } from '../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'

const messages = defineMessages({
  edit: 'Shared.action.edit',
  label: 'FollowUp.report.notification',
  helperText: 'FollowUp.report.notificationInfo',
  emailPlaceholder: 'FollowUp.report.enterEmail',
  invalidEmail: 'FollowUp.report.notification.invalidEmail',
  verifyEmail: 'FollowUp.report.notification.verifyEmail',
  saveCompanyEmail: 'FollowUp.report.saveCompanyEmail',
})

type EmailNotificationsCheckboxAbstractProps = {
  isEmailNotificationEnabled: boolean
  onSubmit: (values: { email: string | null }) => boolean | Promise<boolean>
  email: string | null
  isEmailVerified: boolean | undefined
}

export const EmailNotificationsCheckboxAbstract = (
  props: EmailNotificationsCheckboxAbstractProps
) => {
  const { isEmailNotificationEnabled, onSubmit, email, isEmailVerified } = props
  const [shouldNotify, setShouldNotify] = useState<boolean>(Boolean(email))
  const [loading, setLoading] = useState<boolean>(false)
  const [isCompanyEmail, setIsCompanyEmail] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { formatMessage } = useIntl()
  const theme = useMantineTheme()

  const form = useForm<{ email: string }>({
    initialValues: {
      email: email ?? '',
    },
    validate: {
      email: value => {
        if (!value) {
          return formatMessage(sharedMessages.invalidInputError)
        }
        if (!isEmail(value)) {
          return formatMessage(messages.invalidEmail)
        }
        return null
      },
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies(form.setFieldValue):
  useEffect(() => {
    form.setFieldValue('email', email ?? '')
    if (email) {
      setShouldNotify(true)
    }
    // form can't be a dependency
  }, [email])

  const submitForm = form.onSubmit(async values => {
    setLoading(true)
    await onSubmit(values)
    setLoading(false)
    setIsEdit(false)
  })

  const emailValue = form.getInputProps('email').value

  useEffect(() => {
    const value = emailValue
    const checkIsCompanyEmail = async () => setIsCompanyEmail(await isCompanyEmailFn(value))
    void checkIsCompanyEmail()
  }, [emailValue])

  if (!isEmailNotificationEnabled) {
    return null
  }

  const shouldShowVerificationAlert = isEmailVerified === false && Boolean(email)
  const isEmailFilled = Boolean(email)
  const showEditButton = isEmailFilled && !isEdit

  return (
    <Stack>
      <Checkbox
        label={
          <>
            <FormattedMessage {...messages.label} />{' '}
            <MoreInfo title={<FormattedMessage {...messages.helperText} />} />
          </>
        }
        checked={shouldNotify}
        onChange={e => {
          if (!e.target.checked) {
            void onSubmit({ email: null })
            form.setFieldValue('email', '')
          }
          setShouldNotify(!shouldNotify)
        }}
      />
      {shouldNotify && (
        <Form
          onSubmit={() => {
            if (isCompanyEmail) {
              return
            }
            submitForm()
          }}
        >
          <Flex justify='space-between' gap='0.5rem'>
            <Box
              sx={{
                width: '100%',
                position: 'relative',
              }}
            >
              <TextInput
                size='md'
                w='100%'
                autoComplete='email'
                type='email'
                placeholder={formatMessage(messages.emailPlaceholder)}
                disabled={Boolean(email) && !isEdit}
                icon={shouldShowVerificationAlert && <Box />}
                {...form.getInputProps('email')}
              />
              {shouldShowVerificationAlert && (
                <Box
                  sx={{
                    position: 'absolute',
                    left: '1rem',
                    top: '0.5rem',
                  }}
                >
                  <Tooltip title={<FormattedMessage {...messages.verifyEmail} />}>
                    <UntitledIcon icon={usAlertTriangle} color={theme.colors['yellow'][8]} />
                  </Tooltip>
                </Box>
              )}
            </Box>
            {showEditButton ? (
              <Button data-test='edit-email' ghost type='primary' onClick={() => setIsEdit(true)}>
                <FormattedMessage {...messages.edit} />
              </Button>
            ) : (
              <>
                {isCompanyEmail ? (
                  <Popconfirm
                    title={formatMessage(messages.saveCompanyEmail)}
                    onConfirm={() => submitForm()}
                  >
                    <Button
                      type='primary'
                      data-test='save-email'
                      htmlType='submit'
                      loading={loading}
                    >
                      <FormattedMessage {...sharedMessages.save} />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type='primary'
                    data-test='save-email'
                    htmlType='submit'
                    loading={loading}
                    onClick={() => submitForm()}
                  >
                    <FormattedMessage {...sharedMessages.save} />
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Form>
      )}
    </Stack>
  )
}
