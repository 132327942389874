import { Button, Form, Input } from '@faceup/ui-base'
import { INSTITUTION_PASSWORD_VALIDATION } from '@faceup/utils'
import { useState } from 'react'
import type { UserInstitution } from '../../Contexts/UserContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { useMedia } from '../../mq'
import useRegion from '../../utils/useRegion'

const messages = defineMessages({
  companyPasswordLabel: 'FollowUp.SearchInstitution.passwordLabel',
  companyPasswordError: 'FollowUp.SearchInstitution.passwordError',
  companyPasswordPlaceholder: 'FollowUp.SearchInstitution.passwordPlaceholder',
})

type Props = {
  proceed: (props: UserInstitution) => void
}

const SearchCompanyForm = ({ proceed }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const isLgDown = useMedia('lgDown')
  const { discoverByReportPassword } = useRegion()

  const [passwordInput, setPasswordInput] = useState({ value: '', error: false })

  const validateForm = () => {
    if (!INSTITUTION_PASSWORD_VALIDATION.test(passwordInput.value.trim())) {
      setPasswordInput({ ...passwordInput, error: true })

      return false
    }

    return true
  }

  return (
    <>
      <Form.Item
        label={<FormattedMessage {...messages.companyPasswordLabel} />}
        {...(passwordInput.error && {
          validateStatus: 'error',
          help: <FormattedMessage {...messages.companyPasswordError} />,
        })}
        data-test='search-company-form-item-password'
        style={{ marginBottom: 6 }}
      >
        <Input
          autoComplete='off'
          size='large'
          data-test='search-company-form-password'
          placeholder={formatMessage(messages.companyPasswordPlaceholder)}
          onChange={({ target: { value } }) => setPasswordInput({ value, error: false })}
        />
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
          <Button
            type='primary'
            htmlType='submit'
            block={isLgDown}
            loading={loading}
            data-test='search-company-form-continue'
            onClick={async () => {
              setLoading(true)
              if (!validateForm()) {
                setLoading(false)
                return
              }

              const discovery = await discoverByReportPassword(passwordInput.value.trim())
              if (discovery) {
                proceed({
                  institutionId: discovery.institutionId,
                  defaultFormId: discovery.reportSourceId,
                  password: passwordInput.value.trim(),
                  type: discovery.type,
                })

                setPasswordInput({ value: '', error: false })
              } else {
                setPasswordInput({ ...passwordInput, error: true })
              }
              setLoading(false)
            }}
          >
            <FormattedMessage {...sharedMessages.continue} />
          </Button>
        </div>
      </Form.Item>
    </>
  )
}

export default SearchCompanyForm
