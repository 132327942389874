import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui-base'
import { Box, Flex, Stack, Title } from '@mantine/core'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { useSavedReportsContext } from '../../hooks/useSavedReportsContext'
import { ReportInfo } from './components'

const messages = defineMessages({
  title: 'FollowUp.reports.title',
  checkAnotherReport: 'FollowUp.report.checkAnotherReport',
})

export const CheckReportList = () => {
  const { reports, loginIntoReport } = useSavedReportsContext()
  const navigate = useNavigate()

  return (
    <Stack spacing='xl'>
      <Flex justify='space-between' align='center' gap='sm'>
        <Title order={4}>
          <FormattedMessage {...messages.title} />
        </Title>
        <Box>
          <Button
            onClick={() => navigate(routes => routes.checkReportForm())}
            icon={<UntitledIcon icon={ulPlus} />}
          >
            <FormattedMessage {...messages.checkAnotherReport} />
          </Button>
        </Box>
      </Flex>
      <Stack>
        {reports.map(report => (
          <ReportInfo
            key={report.id}
            report={report}
            onClick={async () => {
              await loginIntoReport(report)
              navigate(routes => routes.report())
            }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
