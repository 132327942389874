import { UntitledIcon } from '@faceup/icons'
import { ulBookmarkMinus } from '@faceup/icons/ulBookmarkMinus'
import { ulDotsHorizontal } from '@faceup/icons/ulDotsHorizontal'
import { ReportStatus } from '@faceup/report'
import { Button } from '@faceup/ui-base'
import { Flex, Menu, Paper, Stack, Text, UnstyledButton } from '@mantine/core'
import moment from 'moment-timezone'
import type { SavedReport } from '../../../Contexts/SavedReportsProvider'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { useSavedReportsContext } from '../../../hooks/useSavedReportsContext'

const messages = defineMessages({
  unlist: 'FollowUp.report.unlist',
})

type ReportInfoProps = {
  report: SavedReport
  onClick: () => void
}

export const ReportInfo = (props: ReportInfoProps) => {
  const { report, onClick } = props
  const { removeReport } = useSavedReportsContext()

  return (
    <UnstyledButton onClick={onClick}>
      <Paper bg='gray.1' p='md'>
        <Stack spacing='sm'>
          <Flex justify='space-between'>
            <Text sx={{ display: 'inline-flex' }}>
              <ReportStatus status={report.status} />
            </Text>
            <Menu>
              <Menu.Target>
                <Button type='link' size='small' onClick={e => e.stopPropagation()}>
                  <UntitledIcon icon={ulDotsHorizontal} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={e => {
                    e.stopPropagation()
                    removeReport(report)
                  }}
                  icon={<UntitledIcon icon={ulBookmarkMinus} />}
                >
                  <FormattedMessage {...messages.unlist} />
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <Stack spacing={4}>
            <Text fw={700} fz={16}>
              {report.category}
            </Text>
            <Text c='prussianBlue.6'>{report.content}</Text>
          </Stack>
          <Text c='prussianBlue.6' fw={600}>
            {moment(report.createdAt).format('LLL')}
          </Text>
        </Stack>
      </Paper>
    </UnstyledButton>
  )
}
