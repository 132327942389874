import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from '@faceup/router'
import { HtmlRenderer, usePageTemplate } from '@faceup/ui'
import { convertBasenameToLanguage } from '@faceup/utils'
import { Box, Flex, MediaQuery, Skeleton, Stack, Title } from '@mantine/core'
import { useContext, useEffect } from 'react'
import { MenuItems } from '../../Components/MenuItems'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { UserContext } from '../../Contexts/UserContext'
import { graphql } from '../../__generated__'
import { desktopBreakpoint } from '../../utils/constants'
import PageNotFound from './Components/PageNotFound'

const query = graphql(`
  query PageViewQuery(
    $id: PageGlobalId!
    $reportSourceId: PublicReportSourceGlobalId!
  ) {
    page(pageId: $id) {
      id
      content
      language
    }
    publicReportSource(reportSourceId: $reportSourceId) {
      id
      isDeleted
      isAnonymityEnabled
      isDefault
      ...InstitutionLayoutMenuItems_reportSource
    }
  }
`)

const PageView = () => {
  const { id } = useParams<{ id: string }>()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const { reportSource } = useContext(UserContext)
  const navigate = useNavigate()
  const { basename } = useContext(LanguageContext)
  const { client } = useContext(LayoutContext)
  const { setIsAnonymous } = useContext(ReportFormContext)

  const isApp = client === 'app'

  const { data, loading } = useQuery(query, {
    variables: {
      id: id ?? '',
      reportSourceId: reportSource?.defaultFormId ?? '',
    },
    skip: !reportSource?.defaultFormId,
  })

  const page = data?.page
  const reportSourceData = data?.publicReportSource

  useEffect(() => {
    const isPageLoaded = page !== undefined
    const doesPageCorrespondsWithLanguage = convertBasenameToLanguage(basename) === page?.language
    if (isPageLoaded && !doesPageCorrespondsWithLanguage) {
      navigate(routes => routes.page())
    }
  }, [basename, navigate, page])

  useEffect(() => {
    if (typeof reportSourceData?.isAnonymityEnabled === 'boolean') {
      setIsAnonymous(reportSourceData.isAnonymityEnabled)
    }
  }, [reportSourceData?.isAnonymityEnabled, setIsAnonymous])

  useEffect(() => {
    setIsDefaultForm(reportSourceData?.isDefault ?? false)
  }, [reportSourceData?.isDefault, setIsDefaultForm])

  const isDeleted = reportSourceData?.isDeleted

  if (data && (!page || isDeleted)) {
    return <PageNotFound />
  }

  return (
    <MediaQuery styles={{ minHeight: 'calc(100vh - 250px)' }} largerThan={desktopBreakpoint}>
      <Flex align='center'>
        {loading || !page ? (
          <Stack spacing='xl'>
            <Title ta='center'>
              <Skeleton height={36} />
            </Title>
            <Skeleton height='40vh' />
          </Stack>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Box
              data-test='pages-content'
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
              }}
            >
              <HtmlRenderer html={fillObject(page).content} />
            </Box>
            <MediaQuery styles={{ display: 'none' }} smallerThan={desktopBreakpoint}>
              {reportSourceData && (
                <Box sx={{ position: 'sticky', bottom: 0, display: isApp ? 'none' : undefined }}>
                  <Box
                    sx={theme => ({
                      background: theme.white,
                      paddingBlock: 16,
                      borderTop: `1px solid ${theme.colors.gray[3]}`,
                    })}
                  >
                    <MenuItems reportSource={reportSourceData} direction='horizontal' />
                  </Box>
                </Box>
              )}
            </MediaQuery>
          </Box>
        )}
      </Flex>
    </MediaQuery>
  )
}

export default PageView
